<template>
  <div class="tw-flex tw-flex-col tw-h-full">
    <div class="tw-py-6 tw-px-12">
      <img :src="logo" style="width: 170px;" alt="LOGO" />
      
      <EntrepriseHeaderBlockTextComponent
        class="tw-mt-10"
        title="Questionnaire RGPD"
        description="Quelques informations sont à saisir. Cela ne vous prendra que 2 minutes !"
        titleFontSize="26px"
        descriptionFontSize="16px"
      />

      <v-form
        ref="form"
        @submit="onSubmit"
        v-model="formValid"
        class="tw-mt-8 tw-w-full pre-register-form"
        autocomplete="off"
      >
        <RadioButtonComponent
          :rules="rules.gender"
          :items="genders"
          :select="form.gender"
          @change="onGenderChange"
        />
        <div class="tw-mt-4 tw-flex tw-gap-x-8">
          <TextFieldComponent
            :rules="rules.firstName"
            :error-messages="errors.firstName"
            v-model="form.firstName"
            label="Prénom"
            style="width: 100%; max-width: 350px;"
          />
          <TextFieldComponent
            :rules="rules.lastName"
            v-model="form.lastName"
            :error-messages="errors.lastName"
            label="Nom"
            style="width: 100%; max-width: 350px;"
          />
          <TextFieldComponent
            :rules="rules.email"
            v-model="form.email"
            label="Adresse e-mail"
            style="width: 100%; max-width: 545px;"
          />
        </div>
        <div class="tw-flex tw-gap-x-8">
          <TextFieldComponent
            :rules="rules.socialReason"
            v-model="form.socialReason"
            :error-messages="errors.socialReason"
            label="Raison sociale de l'entreprise"
            style="width: 100%; max-width: 350px;"
          />
          <TextFieldComponent
            :rules="rules.phone"
            v-model="form.phone"
            :error-messages="errors.phone"
            label="Numéro de téléphone"
            hide-details
            style="width: 100%; max-width: 350px;"
            @input="autoSpacePhoneNumber"
          />
          <div class="tw-flex tw-justify-between tw-w-full" style="max-width: 545px;">
            <TextFieldComponent
              :rules="rules.exactNbrEmployees"
              v-model="form.exactNbrEmployees"
              :error-messages="errors.exactNbrEmployees"
              label="Nombre de salariés"
              style="width: 37%;"
              type="number"
              min="0"
              @input="() => form.exactNbrEmployees = form.exactNbrEmployees < 0 ? 0 : form.exactNbrEmployees"
            />
            <span style="padding-top: 2.25rem;" :style="{ color: theme.ctaBgColor }">Ou</span>
            <SelectBoxComponent
              :rules="rules.nbrEmployees"
              v-model="form.nbrEmployees"
              :items="nbrEmployersItems"
              label="Ou tranche du nombre de salariés"
              style="width: 56%;"
              no-single-line
            />
          </div>
        </div>

        <div class="mt-8 tw-flex">
          <CheckBoxComponent class="tw-cursor-pointer" :switched="hasAgreedToTerms" @toggle="hasAgreedToTerms = !hasAgreedToTerms" />
          <span style="width: 100%; max-width: 1200px; line-height: 14px;" class="tw-ml-2 tw-text-xs" @click="hasAgreedToTerms = !hasAgreedToTerms">Je consens à l'utilisation de mes informations à des fins spécifiques de traitement de mon questionnaire RGPD. Nous nous engageons à respecter la confidentialité de vos informations personnelles. Votre adresse e-mail ne sera pas partagée avec des tiers sans votre accord préalable, sauf si cela est requis par la loi.</span>
        </div>

        <div class="tw-flex tw-justify-center tw-pb-12 md:tw-pb-20 tw-mt-14">
          <PrimaryButtonComponent
            :style="{
              backgroundColor: `${(formValid && hasAgreedToTerms) ? theme.ctaBgColor : '#F4F4F4'} !important`,
              color: `${(formValid && hasAgreedToTerms) ? theme.ctaTextColor : 'black'} !important`,
            }"
            elevation="0"
            :loading="isSendingForm"
            :disabled="!(formValid && hasAgreedToTerms) || isSendingForm"
            type="submit"
          ><span style="font-size: 16px;">Valider</span></PrimaryButtonComponent>
        </div>
      </v-form>
    </div>

    <Footer class="tw-w-full tw-mt-auto" />
  </div>
</template>

<script>
import EntrepriseHeaderBlockTextComponent from "@/components/common/EntrepriseHeaderBlockTextComponent";
import Footer from "@/components/layout/Footer";
import RadioButtonComponent from "@/components/common/RadioButtonComponent";
import TextFieldComponent from "@/components/common/TextFieldComponent";
import PrimaryButtonComponent from "@/components/common/PrimaryButtonComponent";
import CheckBoxComponent from "@/components/common/CheckBoxComponent";
import { validationRules } from "@/utils/validation-rules";
import { preregister } from "@/features/auth/services/gpmse.api"
import { getUserDataByToken } from "@/features/auth/services/appApi";

export default {
  name: "GdprQuizGpmseRegister",
  components: {
    EntrepriseHeaderBlockTextComponent,
    Footer,
    RadioButtonComponent,
    TextFieldComponent,
    PrimaryButtonComponent,
    CheckBoxComponent,
  },
  data() {
    return {
      formValid: false,
      genders: [
        {
          value: "male",
          label: "Monsieur",
        },
        {
          value: "female",
          label: "Madame",
        },
      ],
      nbrEmployersItems: [
        { label: "1 - 2", value: "1-2" },
        { label: "3 - 9", value: "3-9" },
        { label: "10 - 49", value: "10-49" },
        { label: "50 - 99", value: "50-99" },
        { label: "100 - 249", value: "100-249" },
        { label: "250 - 499", value: "250-499" },
        { label: "500 - 2499", value: "500-2499" },
        { label: "2500 - 4999", value: "2500-4999" },
        { label: "5000+", value: "5000+" },
      ],
      rules: {
        firstName: [
          validationRules.required,
          () => validationRules.asyncError(this.errors, "firstName"),
        ],
        lastName: [
          validationRules.required,
          () => validationRules.asyncError(this.errors, "lastName"),
        ],
        gender: [
          validationRules.required,
          () => validationRules.asyncError(this.errors, "gender"),
        ],
        email: [
          validationRules.required,
          validationRules.email,
          () => validationRules.asyncError(this.errors, "email"),
        ],
        socialReason: [
          validationRules.required,
          () => validationRules.asyncError(this.errors, "socialReason"),
        ],
        phone: [
          validationRules.required,
          () => validationRules.asyncError(this.errors, "phone"),
        ],
        nbrEmployees: [
          () => validationRules.asyncError(this.errors, "nbrEmployees"),
        ],
      },
      form: {
        firstName: "",
        lastName: "",
        email: "",
        gender: "",
        socialReason: "",
        phone: "",
        exactNbrEmployees: null,
        nbrEmployees: "",
        token: "",
      },
      errors: {
        firstName: "",
        lastName: "",
        email: "",
        gender: "",
        socialReason: "",
        phone: "",
        exactNbrEmployees: "",
        nbrEmployees: "",
      },
      isSendingForm: false,
      hasAgreedToTerms: false,
    }
  },
  computed: {
    logo() {
      return this.$store.state.referrer.logo;
    },
    theme() {
      return this.$store.state.referrer.theme;
    },
  },
  mounted() {
    if (this.$route?.params?.token) {
      getUserDataByToken(this.$route.params.token)
        .then(({ data }) => {
          this.form.firstName = data?.data?.firstName || "";
          this.form.lastName = data?.data?.lastName || "";
          this.form.email = data?.data?.email || "",
          this.form.gender = data?.data?.gender || "";
          this.form.socialReason = data?.data?.workInCompany?.socialReason || "";
          this.form.phone = data?.data?.phone || "";
          this.form.exactNbrEmployees = data?.data?.workInCompany?.exactNbrEmployees || null;
          this.form.nbrEmployees = data?.data?.workInCompany?.nbrEmployees || "";
          this.form.token = this.$route?.params?.token || "";
        })
        .catch((error) => {
          console.log(error);
          this.$snackbar.showAlertMessage({
            message: error?.response?.data?.message ||
              "Une erreur est survenue lors de la récupération de vos informations",
            type: "error",
          });
          this.$router.push({ name: "GdprQuizGpmse.landing" });
        });
    }
  },
  methods: {
    onSubmit($event) {
      $event.preventDefault();

      if (!this.hasAgreedToTerms) {
        this.$snackbar.showAlertMessage({
          message: "Vous devez accepter les conditions d'utilisation pour continuer",
          type: "error",
        });
        return;
      }

      this.isSendingForm = true;

      preregister(this.form)
        .then(({ data }) => {
          this.$store.commit("SET_GPMSE_USER_TOKEN", data.finishInscriptionToken);
          this.$store.commit("SET_GPMSE_COMPANY_SOCIAL_REASON", this.form.socialReason);
          this.$router.push({ name: "GdprQuizGpmse.quiz" });
        })
        .catch((error) => {
          console.log(error);
          this.$snackbar.showAlertMessage({
            message: (
              (Array.isArray(error?.response?.data?.message) && error?.response?.data?.message[0]) ||
              error?.response?.data?.message ||
              "Une erreur est survenue"
            ),
            type: "error",
          });
        })
        .finally(() => this.isSendingForm = false);
    },
    onGenderChange(value) {
      this.form.gender = value;
    },
    autoSpacePhoneNumber() {
      this.form.phone = this.form.phone.replace(/[^0-9]/g, "");
      this.form.phone = this.form.phone.replace(/(\d{2})(?=\d)/g, "$1 ").trim();
    },
  },
}
</script>

<style>
.pre-register-form label {
  font-size: 14px !important;
}
</style>