<template>
  <div id="collaborateurs-table" class="tw-min-h-[500px]" style="min-height: 500px;">
    <div class="tw-flex tw-flex-row tw-items-center tw-space-y-0 tw-space-x-2 tw-mb-3 tw-mt-2">
          <search-text-field-component
            v-model="keyWord"
            label="Rechercher"
            append-icon="mdi-magnify"
            class="tw-mr-6"
            @change="search"
                />
          <div class="tw-text-xs tw-font-bold tw-flex tw-justify-end">
            <span>Filtrer par:</span>
          </div>

          <select-box-collaborator-component
            v-model="filter.gouvernance"
            class="tw-pt-1 filter-collaborator-select"
            label="Gouvernance"
            :items="gouvernance"/>
          <select-box-collaborator-component
            v-model="filter.ecosysteme"
            class="tw-pt-1 filter-collaborator-select"
            label="Ecosystème"
            :items="ecosysteme"/>
          <select-box-collaborator-component
            v-model="filter.elearning"
            class="tw-pt-1 filter-collaborator-select"
            label="E-learning"
            :items="elearning"/>
        </div>
    <content-placeholders v-if="loading_table">
      <content-placeholders-text :lines="10" />
    </content-placeholders>
    <!-- table  -->
    <v-simple-table v-else>
      <template v-slot:default>
        <thead>
          <tr>
            <th>
                <div @click="addToOrder('firstName')" class="tw-cursor-pointer tw-flex">
                  <span>Collaborateur</span>
                  <img src="@/assets/images/icons/sort-desc.svg" v-if="orderby=='firstName' && orderType=='desc'" alt="">
                  <img src="@/assets/images/icons/sort-asc.svg" v-else-if="orderby=='firstName' && orderType=='asc'" alt="">
                  <img src="@/assets/images/icons/no-sort.svg" v-else alt="">
                </div>
            </th>
            <th>
                <div @click="addToOrder('email')" class="tw-cursor-pointer tw-flex">
                  <span>Email</span>
                  <img src="@/assets/images/icons/sort-desc.svg" v-if="orderby=='email' && orderType=='desc'" alt="">
                  <img src="@/assets/images/icons/sort-asc.svg" v-else-if="orderby=='email' && orderType=='asc'" alt="">
                  <img src="@/assets/images/icons/no-sort.svg" v-else alt="">
                </div>
            </th>
            <th>
              <div @click="addToOrder('function')" class="tw-cursor-pointer tw-flex">
                <span>Fonction</span>
                <img src="@/assets/images/icons/sort-desc.svg" v-if="orderby=='function' && orderType=='desc'" alt="">
                <img src="@/assets/images/icons/sort-asc.svg" v-else-if="orderby=='function' && orderType=='asc'" alt="">
                <img src="@/assets/images/icons/no-sort.svg" v-else alt="">
              </div>
            </th>
            <th>
              <div class="tw-flex tw-justify-center tw-cursor-pointer">
                <span>Gouvernance</span>
                <!-- <img src="@/assets/images/icons/sort-desc.svg" v-if="orderby=='gouvernance' && orderType=='desc'" alt="">
                <img src="@/assets/images/icons/sort-asc.svg" v-else-if="orderby=='gouvernance' && orderType=='asc'" alt="">
                <img src="@/assets/images/icons/no-sort.svg" v-else alt=""> -->
              </div>
            </th>
            <th>
              <div class="tw-flex tw-justify-center tw-cursor-pointer">
                <span>Écosystème</span>
                <!-- <img src="@/assets/images/icons/sort-desc.svg" v-if="orderby=='ecosysteme' && orderType=='desc'" alt="">
                <img src="@/assets/images/icons/sort-asc.svg" v-else-if="orderby=='ecosysteme' && orderType=='asc'" alt="">
                <img src="@/assets/images/icons/no-sort.svg" v-else alt=""> -->
              </div>
            </th>
            <th>
              <div @click="addToOrder('elearning')" class="tw-flex tw-justify-center tw-cursor-pointer">
                  <span>E-learning</span>
                <!-- <img src="@/assets/images/icons/sort-desc.svg" v-if="orderby=='elearning' && orderType=='desc'" alt="">
                <img src="@/assets/images/icons/sort-asc.svg" v-else-if="orderby=='elearning' && orderType=='asc'" alt="">
                <img src="@/assets/images/icons/no-sort.svg" v-else alt=""> -->
              </div>
            </th>
            <th>
              <div class="tw-flex tw-justify-center">
                  <span>Admin</span>
              </div>
            </th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody v-if="data.length > 0">
          <tr v-for="item in data" :key="item.id">
            <td :title="item.firstName + ' ' + item.lastName" :class="{ 'color-gray': item.status == 0 }" class="limit-length">{{ (item.firstName + " " + item.lastName).length>30?(item.firstName + " " + item.lastName).substring(0,30)+'...':item.firstName + " " + item.lastName }}</td>
            <td :title="item.email" :class="{ 'color-gray': item.status == 0 }" class="limit-length">{{ item.email }}</td>
            <td :title="item.function" :class="{ 'color-gray': item.status == 0 }" class="limit-length">{{ item.function }}</td>
            <td v-html="handleGovernanceSheet(item.gouvernance)"></td>
            <td v-html="handleEcoSystemSheet(item.ecosystem,item.inactive)"></td>
            <td>
              <round :items="item" :color="item.elearning" />
            </td>
            <td>
              <div class="tw-flex tw-justify-center tw-items-center" style="width: 100%;">
                <div class="tw-w-2 tw-h-2 tw-rounded-full tw-flex tw-justify-center tw-items-center" :style="{background: item.adminStatusColor}">
                </div>
              </div>
            </td>
            <td>
              <div class="tw-w-100 tw-flex tw-justify-center tw-items-center tooltip">
                <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="11" cy="11" r="10.5" stroke="var(--cta-bg-color)"/>
                  <circle cx="1" cy="1" r="1" transform="matrix(1 0 0 -1 5 12)" fill="var(--cta-bg-color)"/>
                  <circle cx="1" cy="1" r="1" transform="matrix(1 0 0 -1 10 12)" fill="var(--cta-bg-color)"/>
                  <circle cx="1" cy="1" r="1" transform="matrix(1 0 0 -1 15 12)" fill="var(--cta-bg-color)"/>
                </svg>
                <div class="tooltiptext">
                  <ul>
                    <li class="tw-cursor-pointer" @click="modifyContact(item)">Modifier</li>
                    <li class="tw-cursor-pointer" @click="deleteAdmin(item)" v-if="item.isAdmin">Supprimer Admin</li>
                    <li class="tw-cursor-pointer" @click="becomeAdmin(item)" v-else>Rendre Admin</li>
                    <li class="tw-cursor-pointer" @click="envoyer(item)">Renvoyer</li>
                    <li class="tw-cursor-pointer" @click="supprimerContact(item)">Supprimer</li>
                  </ul>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="100%" class="text-center">
              Pas de données disponibles
            </td>
          </tr>
        </tbody>
        <tfoot  v-if="length > 1">
          <tr>
            <td colspan="12">
              <footer-table-pagination-component :limit="perPage" :choosingPage="choosedPage" :length="length" :page="page" @changeLimitAndPage="changeLimitAndPage" @changePage="changePage" />
            </td>
          </tr>
        </tfoot>
      </template>
    </v-simple-table>
    <!-- end table  -->
    <ConfirmeDeletePopup v-if="selectedItem && openPopup" :openPopup="openPopup" :item="selectedItem"
      v-on:fetchData="pagination" @close="openPopup = false" />
    <ModifyPopup v-if="selectedItem && modifyPopup" :openPopup="modifyPopup" :item="selectedItem"
      v-on:fetchData="pagination" @modifyClose="modifyPopup = false" />
    <BecomeAdminPopup v-if="selectedItem && isBecomeAdminPopup" :openPopup="isBecomeAdminPopup" :item="selectedItem"
      v-on:fetchData="pagination" @PopupClose="isBecomeAdminPopup = false" />
  </div>
</template>
<script>
import ConfirmeDeletePopup from "@/features/Collaborateurs/components/ConfirmeDeletePopup";
import ModifyPopup from "@/features/Collaborateurs/components/ModifyPopup";
import { collaboratorbecomeAdmin, inviteCollaborator, collaboratordeleteAdmin } from "../services/appApi";
import { getNewCollaboratorsPaginate } from "@/features/company/services/appApi";
import round from "@/components/common/Round";
import FooterTablePaginationComponent from '@/components/common/FooterTablePaginationComponent.vue';
import SelectBoxCollaboratorComponent from '../../../components/common/SelectBoxCollaboratorComponent.vue';
import SearchTextFieldComponent from '../../../components/common/SearchTextFieldComponent.vue';
import BecomeAdminPopup from "./BecomeAdminPopup.vue";
export default {
  name: "CollaborateursFormAddComponent",
  computed: {
    formSubmitButton() {
      return "Confirmer";
    },
    referer() {
      return this.$store.state.referrer;
    }
  },
  components: {
    ConfirmeDeletePopup,
    round,
    ModifyPopup,
    FooterTablePaginationComponent,
    SelectBoxCollaboratorComponent,
    SearchTextFieldComponent,
    BecomeAdminPopup
},
  data() {
    return {
      keyWord: '',
      word: "",
      orderby: "",
      orderType: "",
      collaborateurs: [],
      perPageChoices: [10, 20, 50, 100],
      perPage: 10,
      page: 1,
      choosedPage: 1,
      data: [],
      openPopup: false,
      modifyPopup: false,
      isBecomeAdminPopup: false,
      selectedItem: null,
      length: 1,
      loading_table: false,
      gouvernance: ["Aucun","Ok","lu", "Non lu", "No"],
      ecosysteme: ["Aucun","Actif","Inactif", "En cours", "No"],
      elearning: ["Aucun","Score excellent", "Score mitigé", "Score faible", "Non renseigné"],
      filter: {
        gouvernance: "",
        ecosysteme: "",
        elearning: "",
      },
    };
  },
  mounted() {
    this.pagination();
  },
  methods: {
    search(){
      this.word=this.keyWord
    },
    changeLimitAndPage(obj) {
      this.perPage = parseInt(obj.limit) ;
      this.page == obj.page?this.pagination():this.changePage(obj.page);
    },
    changePage(page){
      this.page=parseInt(page);
      setTimeout(()=>{
        document.querySelector('#collaborateurs-table').scrollIntoView({
          behavior: 'smooth'
        });
      },200)
    },
    addToOrder(item) {
      if (this.orderby == item && this.orderType == "desc") {
        this.orderby = null
        this.orderType = null
      } else {
        if (this.orderby == item) {
          this.orderType = "desc"
        }
        else {
          this.orderby = item
          this.orderType = "asc"
        }
      }
    },
    supprimerContact(item) {
      this.selectedItem = item;
      this.openPopup = true;
    },
    modifyContact(item) {
      this.selectedItem = item;
      this.modifyPopup = true;
    },
    becomeAdmin(item) {
      collaboratorbecomeAdmin({
        collaboratorId: item.id,
      })
        .then((res) => {
          this.$snackbar.showAlertMessage({
            message: res.data.message,
            type: "success",
          });
          this.data = this.data.map((el) => {
            if (el.email === item.email) {
              el.becomeAdminMailSent = true;
              el.adminStatusColor = '#E8973E';
            }
            return el;
          });
        })
        .catch(({ response }) => {
          if (response.data.status == 422) {
            Object.entries(response.data.errors).forEach(([key, value]) => {
              this.errors[key] = this.getValidationMessage(key, value);
            });
          } else {
            this.$snackbar.showAlertMessage({
              message: response.data.message,
              type: "error",
            });
          }
        })
    },
    deleteAdmin(item) {
      collaboratordeleteAdmin({
        collaboratorId: item.id,
      })
        .then((res) => {
          this.$snackbar.showAlertMessage({
            message: res.data.message,
            type: "success",
          });
          this.data = this.data.map((el) => {
            if (el.email === item.email) {
              el.becomeAdminMailSent = false;
              el.isAdmin = false;
              el.adminStatusColor = '#C4C4C4';
            }
            return el;
          });
        })
        .catch(({ response }) => {
          if (response.data.status == 422) {
            Object.entries(response.data.errors).forEach(([key, value]) => {
              this.errors[key] = this.getValidationMessage(key, value);
            });
          }
        })
    },
    envoyer(item) {
      inviteCollaborator(item.id)
        .then((res) => {
          this.$snackbar.showAlertMessage({
            message: res.data.message,
            type: "success",
          });
          //this.fetchData();
        })
        .catch(({ response }) => {
          this.$snackbar.showAlertMessage({
            message: response.status.message,
            type: "error",
          });
        });
    },
    pagination(value = this.page, keyWord = this.word) {
      this.loading_table = true;
      this.filter.gouvernance = this.filter.gouvernance ? this.filter.gouvernance : 'aucun';
      this.filter.ecosysteme = this.filter.ecosysteme ? this.filter.ecosysteme : 'aucun';
      this.filter.elearning = this.filter.elearning ? this.filter.elearning : 'aucun';
      getNewCollaboratorsPaginate('all', value, keyWord,this.perPage, this.filter, this.orderby, this.orderType)
        .then((res) => {
          this.data = [];
          res.data.items.forEach(item => {
            const gouvernance = item.modules.filter(el => el.type === 'governance')[0] ? item.modules.filter(element => element.type === 'governance')[0] : null;
            const ecosystem = item.modules.filter(el => el.type === 'ecosystem')[0] ? item.modules.filter(element => element.type === 'ecosystem')[0] : null;
            const elearning = item.modules.filter(el => el.type === 'elearning')[0] ? item.modules.filter(element => element.type === 'elearning')[0] : null;
            this.data.push({
              inactive: item.inactive,
              ...item,
              gouvernance,
              ecosystem,
              elearning,
              adminStatusColor: (
                (!item.isAdmin && item.becomeAdminMailSent && '#E8973E') ||
                (item.isAdmin && '#27AE60') ||
                '#C4C4C4'
              )
            })
          });
          this.page = res.data.meta.currentPage;
          this.length = res.data.meta.totalPages;
        })
        .catch(({ response }) => {
          this.$snackbar.showAlertMessage({
            message: response.data.message,
            type: "error",
          });
        })
        .finally(() => {
          this.loading_table = false;
        });
    },
    handleGovernanceSheet(item) {
      if (item && item.status === 0) {
        return `<div class="tw-flex tw-justify-center tw-items-center">
                  <div class="shape tw-rounded-full tw-flex tw-justify-center tw-items-center"
                       style="border: 1px solid ${this.referer.theme.ctaBgColor}; color: ${this.referer.theme.ctaBgColor}"
                  >
                    NON LU
                  </div>
                </div>`;
      }
      else if (item && item.status === 1) {
        return `<div class="tw-flex tw-justify-center tw-items-center">
                  <div class="shape tw-rounded-full tw-flex tw-justify-center tw-items-center tw-text-white" style="background: #E8973E">
                    LU
                  </div>
                </div>`;
      }
      else if (item && item.status === 2) {
        return `<div class="tw-flex tw-justify-center tw-items-center">
                  <div class="shape tw-rounded-full tw-flex tw-justify-center tw-items-center tw-text-white" style="background:#27AE60">
                    OK
                  </div>
                </div>`;
      }
      else {
        return `<div class="tw-flex tw-justify-center tw-items-center">
                  <div class="shape tw-rounded-full tw-flex tw-justify-center tw-items-center tw-text-white" style="background: #C4C4C4">
                    NO
                  </div>
                </div>`;
      }
    },
    handleEcoSystemSheet(item, inactive) {
      if (item && item.status === 0) {
        return `<div class="tw-flex tw-justify-center tw-items-center">
                  <div class="shape tw-rounded-full tw-flex tw-justify-center tw-items-center tw-text-white" style="background: #E8973E;">
                    En cours
                  </div>
                </div>`;
      }
      else if (item && item.status === 1) {
        if(inactive)
          return `<div class="tw-flex tw-justify-center tw-items-center">
                    <div class="shape tw-rounded-full tw-flex tw-justify-center tw-items-center tw-text-white" style="background: #d51609">
                      Inactif
                    </div>
                  </div>`;
        else
          return `<div class="tw-flex tw-justify-center tw-items-center">
                    <div class="shape tw-rounded-full tw-flex tw-justify-center tw-items-center tw-text-white" style="background: #27AE60">
                      Actif
                    </div>
                  </div>`;
        
      }
      else {
        return `<div class="tw-flex tw-justify-center tw-items-center">
                  <div class="shape tw-rounded-full tw-flex tw-justify-center tw-items-center tw-text-white" style="background: #C4C4C4">
                    NO
                  </div>
                </div>`;
      }
    },
  },
  watch: {
    word() {
      this.pagination()
    },
    filter:{
      handler() {
        this.pagination()
        this.page = 1
      },
      deep: true
    },
    orderby() {
        this.pagination()
    },
    orderType() {
        this.pagination()
    },
    page() {
      this.choosedPage = this.page
        this.pagination()
    }
  }
};
</script>


<style lang="scss" scoped>
.color-gray{
  color: #C4C4C4 !important;
}
.select-option{
  position: relative;
  border: 1px solid var(--cta-bg-color);
  border-radius: 5px;
  padding: 5px;
  color: var(--cta-bg-color);
  font-size: 12px;
  font-weight: 600;
  text-align: left;
  width: 100%;
  height: 100%;
}
.select-page{
  border: 1px solid var(--cta-bg-color);
  border-radius: 5px;
  padding: 5px;
  color: var(--cta-bg-color);
  font-size: 12px;
  font-weight: 600;
  text-align: left;
  width: 100%;
  height: 100%;
}
.input-text{
  padding: 0 6px;
  color: var(--cta-bg-color);
  font-size: 12px;
  font-weight: 600;
  text-align: left;
  width: 30%;
  height: 100%;
}
.flesh{
  position: absolute;
  top: 5px;
  right: 5px;
  width: 20px;
  height: 20px;
  pointer-events: none;
}

.btn-pagination{
  border: 1px solid var(--cta-bg-color);
  border-radius: 5px;
  padding: 5px;
  text-align: center;
  color: #fff;
  background: var(--cta-bg-color);
  font-size: 12px;
  font-weight: 600;
  width: 100%;
  height: 100%;
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  background-color: white;
  color: #000;
  text-align: center;
  border-radius: 6px;
  padding: 0;
  border: 1px solid #14477E;
  position: absolute;
  z-index: 1000;
  top: 22px;
  right: -30px;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.tooltip .tooltiptext li {
  padding: 0.5rem 1rem;
}

.tooltip .tooltiptext li:hover {
  background-color: rgba(206, 206, 206, 0.39);
}
</style>
