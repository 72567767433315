<template>
  <div id="Questionnaire-container">
    <EntrepriseHeaderBlockTextComponent
      class="tw-w-full"
      title="Mon questionnaire"
      description="Vos scores et vos éventuels axes d’amélioration."
    />
    <template v-if="loading_qst">
      <content-placeholders class="">
        <content-placeholders-text :lines="10" />
      </content-placeholders>
    </template>
    <template v-else>
      <div class="tw-grid tw-grid-cols-5 tw-gap-4 tw-w-full" v-if="showQuestion">
        <div class="tw-col-span-5 lg:tw-col-span-2 box-questionnaire">
          <span class="score" style="margin-top: 0;" :class="getColorBadge()">
            {{ total !== null ? total.toFixed() : 0 }} / 100
          </span>

          <div class="tw-mt-4 tw-flex tw-flex-col tw-items-center tw-mx-auto tw-w-full">
            <div class="tw-w-full">
              <div 
                class="tw-flex tw-items-center tw-justify-around tw-font-semibold tw-text-white tw-text-sm tw-rounded-lg"
                :style="{ backgroundColor: `${theme.ctaBgColor} !important` }"
                style="padding: 2px 4px;"
              >
                <span>0</span>
                <span>20</span>
                <span>40</span>
                <span>60</span>
                <span>80</span>
                <span>100</span>
              </div>
              <div style="width: 82%; margin-left: 10px;">
                <svg class="tw-mt-1" :class="getColorBadge()" :style="{ marginLeft: `${total}%` }" xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" viewBox="0 0 24 24"><path fill="currentColor" d="M3 19h18a1.002 1.002 0 0 0 .823-1.569l-9-13c-.373-.539-1.271-.539-1.645 0l-9 13A.999.999 0 0 0 3 19" /></svg>
              </div>
            </div>

            <span class="tw-text-sm tw-font-bold tw-mt-2" :class="getColorBadge()">{{ scoreMessage.message }}</span>

            <p class="tw-text-sm tw-text-center" style="margin-top: .25rem; line-height: 1rem;">
              {{ scoreMessage.description }}
            </p>
          </div>

          <hr />
          <v-btn
              elevation="1"
              text
              rounded
              large
              @click="MiseAjour()"
              class="tw-text-white victor-submit-button tw-m-auto"
          >
            Mettre à jour
          </v-btn>
          <p>
            Du changement concernant votre conformité ?
            Une fois par an, vous avez la possibilité de modifier vos réponses
            au questionnaire et faire évoluer votre score.
          </p>
        </div>
        <div class="tw-col-span-5 lg:tw-col-span-3 box-score">
          <h2>Mon score en détails</h2>
          <v-simple-table id="contact-table">
            <template v-slot:default>
              <thead>
              <tr>
                <th>Chapitre</th>
                <th>Score</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item, key) in table_data" :key="item.id">
                <td>{{ key + 1 }}. {{ item.chapter.label }}</td>
                <td>
                  <span :class="getBadge(item.status)" v-if="key !== 9">
                    {{ item.scorePercent !== null ? item.scorePercent.toFixed() : 0 }} / 100
                  </span>
                  <span 
                    v-if="key === 9" 
                    @click="showDetailsDialog = true"
                    class="tw-ml-2 tw-text-xs tw-px-3 tw-py-1 tw-rounded-full tw-font-normal tw-cursor-pointer"
                    style="border: 1px solid #716861; color: #716861;"
                  >Détail</span>
                </td>
              </tr>
              <tr>
                <td>Total:</td>
                <td>
                  <span class="tw-whitespace-nowrap" :class="getLgBadge()"
                  >{{ total !== null ? total.toFixed() : 0 }}  / 100</span>
                </td>
              </tr>
              </tbody>
            </template>
          </v-simple-table>
          <div class="tw-w-full tw-flex tw-items-center tw-justify-center">
            <v-btn
                elevation="1"
                text
                rounded
                large
                @click="voirDetail()"
                class="tw-text-white victor-submit-button-detail"
            >
              Voir le détail
            </v-btn>
          </div>

        </div>
      </div>
      <EmptyComponent v-else />
    </template>

    <!-- New Dialog Component -->
    <v-dialog
      v-if="showDetailsDialog"
      v-model="showDetailsDialog"
      max-width="800px"
    >
      <v-card flat>
        <v-card-title class="tw-text-lg tw-font-bold">
          10. {{ table_data[9].chapter.label }}
          <v-spacer></v-spacer>
          <v-btn
            icon
            @click="showDetailsDialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <div class="tw-space-y-6">
            <div 
              v-for="(question, questionIndex) in chapter10" :key="question.id" 
              class="tw-pb-4"
              :class="{ 'tw-border-b tw-border-gray-200': questionIndex !== chapter10.length - 1 }"
            >
              <h3 class="tw-font-medium tw-mb-3">
                10.{{ questionIndex + 1 }}. {{ question.label }}
              </h3>
              <div class="tw-pl-4">
                <div 
                  v-for="(response, responseIndex) in question.responses" 
                  :key="responseIndex"
                  class="tw-mb-2"
                >
                  <span class="tw-text-gray-700">
                    {{ question.responses.length > 1 ? "• " : "" }}{{ response }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import EntrepriseHeaderBlockTextComponent from "@/components/common/EntrepriseHeaderBlockTextComponent";
import EmptyComponent from "@/features/company/components/Questionnaire/EmptyComponent";
import { getQuiz } from "@/features/company/services/appApi";

export default {
  name: "MonEntrepriseQuestionnairePage",

  components: {
    EntrepriseHeaderBlockTextComponent,
    EmptyComponent,
  },
  data() {
    return {
      showDetailsDialog: false,
      loading_qst: false,
      table_data: [],
      score: null,
      scoreTotal: null,
      showQuestion: false,
      scoreMessages: [
        {
          message: "C’est un excellent score !",
          description: `
            Vous êtes dans une excellente dynamique de conformité,
            continuez à maintenir ces bonnes pratiques et
            renouvelez l’exercice régulièrement pour rester en tête.
          `,
          condition: (score) => score >= 85,
        },
        {
          message: "C’est un bon score !",
          description: `
            Quelques améliorations mineures, et davantage de bonnes pratiques,
            vous permettraient d’atteindre une conformité encore plus forte.
            Continuez sur cette voie pour progresser !
          `,
          condition: (score) => score >= 70 && score < 85
        },
        {
          message: "C’est un score encourageant !",
          description: `
            Des améliorations sont nécessaires pour garantir
            une meilleure conformité. En travaillant sur
            quelques points spécifiques, vous pourrez rapidement progresser.
          `,
          condition: (score) => score >= 55 && score < 70
        },
        {
          message: "C’est un score honorable !",
          description: `
            Ce résultat montre que des efforts doivent être faits pour assurer
            une conformité adéquate. Il est essentiel de revoir certaines pratiques
            et de mettre en place des mesures correctives.
          `,
          condition: (score) => score >= 40 && score < 55
        },
        {
          message: "C’est un score frustrant !",
          description: `
            Il est temps d’entreprendre des actions pour rectifier le tir.
            Ce score indique que des failles subsistent en termes de conformité.
            Un plan d’action urgent est nécessaire pour améliorer cette situation.
          `,
          condition: (score) => score < 40
        }
      ],
      chapter10: [],
      canShowDetails: false,
    };
  },
  computed: {
    scoreMessage() {
      return this.scoreMessages.find((message) => message.condition(this.total));
    },
    theme() {
      return this.$store.state.referrer.theme;
    },
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.loading_qst = true;
      getQuiz()
        .then((res) => {
          this.table_data   = res.data.data.quiz.quizChapters.sort((a, b) => a.chapterId - b.chapterId);
          this.scoreTotal   = res.data.data.quiz.status;
          this.showQuestion = !res.data.data.quiz.isDraft;
          this.total = res.data.data.quiz.scorePercent;

          this.chapter10 = this.table_data.find(chapter => chapter.chapterId === 10).quizQuestions.map((q) => ({
            label: q.question.label,
            responses: q.responses.map(r => r.label)
          }));
        })
        .catch(({ response }) => {
          this.$snackbar.showAlertMessage({
            message: response.data.message,
            type: "error",
          });
        })
        .finally(() => {
          this.loading_qst = false;
        });
    },
    getBadge(score) {
      if (score =="A") return "victor-badge-green";
      else if (score =="B") return "victor-badge-yellow";
      else return "victor-badge-red";
    },
    getLgBadge() {
      if (this.scoreTotal =="OK_GO") return "victor-lg-badge-green";
      else if (this.scoreTotal =="GO_SR_A") return "victor-lg-badge-yellow";
      else if (this.scoreTotal =="GO_SR_B") return "victor-lg-badge-yellow";
      else return "victor-lg-badge-red";
    },
    getColorBadge() {
      if (this.scoreTotal =="OK_GO") return "victor-green";
      else if (this.scoreTotal =="GO_SR_A") return "victor-yellow";
      else if (this.scoreTotal =="GO_SR_B") return "victor-yellow";
      else return "victor-red";
    },
    voirDetail() {
      this.$router.push({
        name: "Company.MonEntreprisePage.Questionnaire.reponses",
      });
    },
    MiseAjour() {
      this.$router.push({
        name: "Company.MonEntreprisePage.Questionnaire.RGBD",
      });
    },
  },
};
</script>
<style lang="scss" scoped>
#Questionnaire-container {
  .slide-fade-enter-active {
    animation: slide-fade .8s;
  }

  .slide-fade-leave-active {
    display: none;
  }

  @keyframes slide-fade {
    0% {
      transform: translateY(-50px);
      opacity: 0;
    }

    100% {
      transform: translateY(0px);
      opacity: 1;
    }
  }
}
</style>
